import * as React from 'react'

import { Navbar, Footer } from '../views'

export default function NftForBeginners(){
  return (
    <main>
        <title>Home Page</title>
        <Navbar />
        <div className="main">
            <div className="container">
                <div className="section centered page">
                    <h1>NFT for beginners</h1>
                    <div className="note">How to mint (purchase) NFT's step by step</div>
                    <div className="content">
                        <p>We welcome every member in our community and we are aware of this is going to be the very first NFT experience for many members here. That's cool and we are happy that you start your journey with us in the NFT space!</p>
                        <p>We have created this tutorial to help you with all the necessary information you need to minting (buying) your very first NFT. As Jesus paved the way for his apostles, it's a pleasure for us to help you put here!</p>
                        <ul>
                            <li>
                                <b>Create a MetaMask wallet</b>
                                <p>This is the first and a very important step. MetaMask wallet is going to be your virtual cryptocurrency and NFT wallet, which you are going to use to interact with our app for minting/buying, holding, and potentially for selling your NFTs in the future. </p>
                                <p>You can download your MetaMask wallet from this link: <a href="https://metamask.io/" target="_blank" rel="noreferrer" >https://metamask.io/</a></p>
                                <blockquote>IMPORTANT: Do not use other websites to download the MetaMask wallet. This is the only official website, and there are many scams out there, you should always double check if you are on this website before installing it.</blockquote>
                                <p>You can install MetaMask as an extension onto your browser, it’s compatible with the following ones</p>
                                <ul>
                                    <li><a href="https://brave.com/download/" target="_blank" rel="noreferrer">Brave (recommended)</a></li>
                                    <li><a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">Google Chrome</a></li>
                                    <li><a href="https://www.mozilla.org/en-US/firefox/" target="_blank" rel="noreferrer">Mozilla Firefox</a></li>
                                    <li><span>Edge (not recommended)</span></li>
                                </ul>
                            </li>
                            <li>
                                <b>Buy crypto</b>
                                <p>You can only buy NFT’s with crypto so you have to create an account on any of the following exchange websites.</p>
                                <ul>
                                    <li><a href="https://www.binance.com/en/activity/referral-entry?fromActivityPage=true&ref=LIMIT_RL2NHPYJ" target="_blank" rel="noreferrer">Binance</a></li>
                                    <li><a href="https://coinbase.com/" target="_blank" rel="noreferrer">Coinbase</a></li>
                                    <li><a href="https://www.kucoin.com/land/register/r/r3G5AWP" target="_blank" rel="noreferrer">KuCoin</a></li>
                                </ul>                        
                                <p>After you created your account you can buy your cryptocurrency. In our case, that is going to be ETH “Ethereum" that you will then use to purchase your NFT. You will need MINTING_PRICE ETH per NFT or (PRESALE_MINTING_PRICE ETH if you are eligible for the presale) plus some extra to pay for the Ethereum "GAS FEES” - in other word, transaction fee.</p>
                                <p>After you buy the ETH, transfer it to your MetaMask wallet that you have created in the first step. Simply copy the MetaMask address of your MetaMask and send the ETH there from your exchange account. </p>
                            </li>
                            <li>
                                <b>Buy your NFT</b>    
                                <p>Finally you are ready to buy your first NFT! Let's go ahead to our website and buy your Crypto Jesus NFT’s!</p>                        
                                <p>When the minting time comes, you have everything prepared to participate! You can see more details about the mining process and timing on this <a href="/">link</a></p>
                                <p>First, connect your MetaMask to our website (click the MetaMask extension in your browser and connect it to the website), then simply select the desired amount of Crypto Jesus that you want to mint, and click the mint button. You have to confirm the minting process in your Metamask wallet popup and you are done! You just minted your first NFT, congratulations!</p>
                                <p>We will provide you a more-detailed guide on minting as the minting days get closer. So, no worries if the minting is still not clear to you.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </main>
  )
}
